import IMG1 from "../../assets/images/BLUE.png"
import IMG2 from "../../assets/images/BLACK.png"
import IMG3 from "../../assets/images/RED.png"
import IMG4 from "../../assets/images/download.png"
import IMG5 from "../../assets/images/download.png"
import IMG6 from "../../assets/images/download.png"
import IMG7 from "../../assets/images/download.png"
import IMG8 from "../../assets/images/download.png"
import IMG9 from "../../assets/images/download.png"
import IMG10 from "../../assets/images/download.png"

const ADVdata = [
    {
        image:IMG1,
        title:"ស្រោមសោរ carbon",
        price:"$8.5",
        delivery:"Free",
    },
    {
        image:IMG2,
        title:"ស្រោមសោរ carbon",
        price:"$8.5",
        delivery:"Free",
    },
    {
        image:IMG3,
        title:"ស្រោមសោរ carbon",
        price:"$8.5",
        delivery:"Free",
    },
]

export {ADVdata};