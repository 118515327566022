import React, { useContext } from 'react'
import "./sidebar.css"
import { SidebarContext } from './context/sidebar-context'
import {IoCloseOutline} from 'react-icons/io5'
import Avatar from './Avatar'

const Sidebar = () => {
    const {open, handleClose} = useContext(SidebarContext);
  return (
    <div className='sidebar__container' style={{right:open?'0px':'-100%'}}>
        <div className="info__container">
            <div className="x__container" onClick={handleClose}><IoCloseOutline className="x-icon" /></div>
            <Avatar />
        </div>
    </div>
  )
}

export default Sidebar