import IMG1 from "../assets/images/click125i.png"
import IMG2 from "../assets/images/click150i.png"
import IMG3 from "../assets/images/click125ib.png"
import IMG4 from "../assets/images/click150ib.png"
import IMG5 from "../assets/images/click.png"
import IMG6 from "../assets/images/clickb.png"
import IMG7 from "../assets/images/click125set.png"
import IMG8 from "../assets/images/click150set.png"
import IMG9 from "../assets/images/click125bset.png"
import IMG10 from "../assets/images/click150bset.png"
import IMG11 from "../assets/images/GOLD + RED SET/125I GOLD.png"
import IMG12 from "../assets/images/GOLD + RED SET/150I GOLD.png"
import IMG13 from "../assets/images/GOLD + RED SET/CLICK GOLD.png"
import IMG14 from "../assets/images/GOLD + RED SET/CLICK RED.png"
import IMG15 from "../assets/images/GOLD + RED SET/125I GOLD SET.png"
import IMG16 from "../assets/images/GOLD + RED SET/150I GOLD SET.png"
import IMG17 from "../assets/images/GOLD + RED SET/STICKER DECALS/125I 2021 BLACK.png"
import IMG18 from "../assets/images/GOLD + RED SET/STICKER DECALS/125I 2021 RED.png"
import IMG19 from "../assets/images/GOLD + RED SET/STICKER DECALS/125I 2021 WHITE.png"
import IMG20 from "../assets/images/GOLD + RED SET/STICKER DECALS/150I 2020 BLUE.png"
import IMG21 from "../assets/images/GOLD + RED SET/STICKER DECALS/150I 2020 ORANGE.png"
import IMG22 from "../assets/images/GOLD + RED SET/STICKER DECALS/150I 2020 RED.png"
import IMG23 from "../assets/images/lights 4.5$ FREE DEL/BLACK.png"
import IMG24 from "../assets/images/lights 4.5$ FREE DEL/BLUE.png"
import IMG25 from "../assets/images/lights 4.5$ FREE DEL/GREEN.png"
import IMG26 from "../assets/images/lights 4.5$ FREE DEL/LIGHT BLUE.png"
import IMG27 from "../assets/images/lights 4.5$ FREE DEL/LIME.png"
import IMG28 from "../assets/images/lights 4.5$ FREE DEL/ORANGE.png"
import IMG29 from "../assets/images/lights 4.5$ FREE DEL/PINK.png"
import IMG30 from "../assets/images/lights 4.5$ FREE DEL/RED.png"
import IMG31 from "../assets/images/lights 4.5$ FREE DEL/YELLOW.png"
import IMG32 from "../assets/images/SPEEDOMETER 1$ EACH + DEL 1/BLACK S.png"
import IMG33 from "../assets/images/SPEEDOMETER 1$ EACH + DEL 1/BLUE S.png"
import IMG34 from "../assets/images/SPEEDOMETER 1$ EACH + DEL 1/CLEAR.png"
import IMG35 from "../assets/images/SPEEDOMETER 1$ EACH + DEL 1/GREEN S.png"
import IMG36 from "../assets/images/SPEEDOMETER 1$ EACH + DEL 1/RED S.png"
import IMG37 from "../assets/images/click160i-img/2022-10-22 16.15.56.jpg"
import IMG38 from "../assets/images/click160i-img/2022-10-22 16.16.01.jpg"
import IMG39 from "../assets/images/click160i-img/2022-10-22 16.16.05.jpg"
import IMG40 from "../assets/images/click160i-img/2022-10-22 16.16.12.jpg"
import IMG41 from "../assets/images/click160i-img/2022-10-22 16.16.18.jpg"
import IMG42 from "../assets/images/click160i-img/2022-10-22 16.16.22.jpg"
import IMG43 from "../assets/images/click160i-img/2022-10-22 16.16.25.jpg"
import IMG44 from "../assets/images/click160i-img/2022-10-22 16.16.29.jpg"
import IMG45 from "../assets/images/312798461_3199091160352614_4711022278458410573_n.jpg"
import IMG46 from "../assets/images/150i original.jpg"
import IMG47 from "../assets/images/click original.jpg"

const clickData = [
    {
        img:IMG46,
        title:"តែម Click 150i ហ្សីន 1 ខាង",
        price:"$4",
        delivery:"$1.5",
    },
    {
        img:IMG47,
        title:"តែម Click ហ្សីន 1 ខាង",
        price:"$8",
        delivery:"1.5$",
    },
    {
        img:IMG45,
        title:"តែម Click 150i ហ្សីន",
        price:"$22",
        delivery:"Free",
    },
    {
        img:IMG1,
        title:"Sticker 125i",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG2,
        title:"Sticker 150i",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG3,
        title:"Sticker 125i Black",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG4,
        title:"Sticker 150i Black",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG11,
        title:"Sticker 125i Gold",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG12,
        title:"Sticker 150i Gold",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG5,
        title:"Sticker Click",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG6,
        title:"Sticker Click Black",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG13,
        title:"Sticker Click Gold",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG14,
        title:"Sticker Click Red",
        initialPrice:"$5",
        price:"$3.5",
        delivery:"Free",
    },
    {
        img:IMG7,
        title:"Sticker 125i Set",
        initialPrice:"$10",
        price:"$6",
        delivery:"Free",
    },
    {
        img:IMG8,
        title:"Sticker 150i Set",
        initialPrice:"$10",
        price:"$6",
        delivery:"Free",
    },
    {
        img:IMG9,
        title:"Sticker 125i Black Set",
        initialPrice:"$10",
        price:"$6",
        delivery:"Free",
    },
    {
        img:IMG10,
        title:"Sticker 150i Black Set",
        initialPrice:"$10",
        price:"$6",
        delivery:"Free",
    },
    // {
    //     img:IMG15,
    //     title:"Sticker 125i Gold Set",
    //     initialPrice:"$10",
    //     price:"$6",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG16,
    //     title:"Sticker 150i Gold Set",
    //     initialPrice:"$10",
    //     price:"$6",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG17,
    //     title:"DECALS/125I 2021 BLACK",
    //     price:"$8",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG18,
    //     title:"DECALS/125I 2021 RED",
    //     price:"$8",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG19,
    //     title:"DECALS/125I 2021 WHITE",
    //     price:"$8",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG20,
    //     title:"DECALS/150I 2020 BLUE",
    //     price:"$8",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG21,
    //     title:"DECALS/150I 2020 ORANGE",
    //     price:"$8",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG22,
    //     title:"DECALS/150I 2020 RED",
    //     price:"$8",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG23,
    //     title:"Click125I/150I lights BLACK",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG24,
    //     title:"Click125I/150I lights BLUE",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG25,
    //     title:"Click125I/150I lights GREEN",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG26,
    //     title:"Click125I/150I lights LIGHT BLUE",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG27,
    //     title:"Click125I/150I lights LIME",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG28,
    //     title:"Click125I/150I lights ORANGE",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG29,
    //     title:"Click125I/150I lights PINK",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG30,
    //     title:"Click125I/150I lights RED",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG31,
    //     title:"Click125I/150I lights YELLOW",
    //     price:"$4.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG32,
    //     title:"Click125I/150I Speedometer sticker",
    //     price:"$1",
    //     delivery:"$1.5",
    // },
    // {
    //     img:IMG33,
    //     title:"Click125I/150I Speedometer sticker",
    //     price:"$1",
    //     delivery:"$1.5",
    // },
    // {
    //     img:IMG34,
    //     title:"Click125I/150I Speedometer sticker",
    //     price:"$1",
    //     delivery:"$1.5",
    // },
    // {
    //     img:IMG35,
    //     title:"Click125I/150I Speedometer sticker",
    //     price:"$1",
    //     delivery:"$1.5",
    // },
    // {
    //     img:IMG36,
    //     title:"Click125I/150I Speedometer sticker",
    //     price:"$1",
    //     delivery:"$1.5",
    // },
    // {
    //     img:IMG37,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$16.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG38,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$16.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG39,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$16.5",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG40,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$22",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG41,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$27",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG42,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$27",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG43,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$22",
    //     delivery:"Free",
    // },
    // {
    //     img:IMG44,
    //     title:"គ្រឿងលេង carbon click125/150i",
    //     price:"$20",
    //     delivery:"Free",
    // },
    

]

export {clickData};