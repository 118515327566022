import IMG1 from "../../assets/images/click160i-img/cbs.png"
import IMG2 from "../../assets/images/click160i-img/ABS.png"
import IMG3 from '../../assets/images/click160i-img/160cbs.png'
import IMG4 from '../../assets/images/click160i-img/gold.jpeg'
import IMG5 from '../../assets/images/click160i-img/goldfullset.jpeg'
import IMG6 from '../../assets/images/click160i-img/siliver.jpeg'
import IMG7 from '../../assets/images/click160i-img/silverset.jpeg'
import IMG8 from "../../assets/images/click160i-img/Screenshot 2023-02-04 at 10.19.03 AM.png"
import IMG9 from "../../assets/images/click160i-img/goldabs.jpeg"





const Click160idata = [
    {
        image:IMG7,
        image2:IMG3,   
        title:"Sticker click 160i CBS",
        price:"$25",
        delivery:"Free",
    },
    {
        image:IMG5,
        image2:IMG9,
        image3:IMG8,
        title:"Sticker click 160i ABS",
        price:"$25",
        delivery:"Free",
    },
    {
        image:IMG6,
        title:"Sticker click 160i CBS 1 ខាង",
        price:"$15",
        delivery:"Free",
    },
    {
        image:IMG4,
        title:"Sticker click 160i ABS 1 ខាង",
        price:"$15",
        delivery:"Free",
    },
 

]

export {Click160idata};