import React from "react";
import ReactDOM from "react-dom";
import App from "./App"
import "./index.css"
import {BrowserRouter} from "react-router-dom"
import SidebarProvider from "./components/context/sidebar-context";
import { AuthContextProvider } from "./components/context/AuthContext";
import { inject } from '@vercel/analytics';
 
inject();




ReactDOM.render(<BrowserRouter><AuthContextProvider><SidebarProvider><App /></SidebarProvider></AuthContextProvider></BrowserRouter>,document.getElementById("root"));



