import React from 'react'
// import LoadingIcons from 'react-loading-icons'
// import { Bars,Puff } from 'react-loading-icons'
import Hearts from 'react-loading-icons/dist/esm/components/hearts'
import ClipLoader from "react-spinners/ClipLoader";
import { PulseLoader} from 'react-spinners';



import './screenloader.css'

const ScreenLoader = ({darkMode}) => {
  return (
    <div className='screenloader' style={darkMode?{background:"#121212"}:null}>
    <ClipLoader color='var(--third-color)' speedMultiplier={1.4} className='loading__icon' />
    <p className='loading__text'>Loading<PulseLoader color='var(--third-color)' size={2} className='loading__icon2' /></p> 
    {/* <Puff stroke="#98ff98" />

<Puff stroke="#98ff98" strokeOpacity={.125} />


<Puff stroke="#98ff98" strokeOpacity={.125} speed={.75} /> */}
    </div>
  )
}

export default ScreenLoader