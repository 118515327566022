import React,{useState,useEffect, useContext} from "react";
import Avatar from "./components/Avatar";
// import Navigation from "./components/products/navigation/Navigation";
import ReactGA from "react-ga";
import Product from "./components/Product";
import Testimonial from "./components/Testimonial";
import {Link,Routes,Route,Navigate} from "react-router-dom";
import Categories from './components/Categories';
import Adv from './components/products/Adv';
import Click160i from "./components/products/Click160i";
import Scoopy from "./components/Scoopy";
import Search from "./components/Search";
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Paper } from "@mui/material";
import CustomizedSwitches from "./components/New";
import Sidebar from "./components/Sidebar";
import { Click160idata } from "./components/products/click160i-data";
import { ADVdata } from "./components/products/adv-data";
import { data } from "./components/product-data";
import ScreenLoader from "./components/ScreenLoader";
import Login from "./components/Login";
import Admin from "./components/Admin";
import { AuthContext } from "./components/context/AuthContext";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "./firebase";







const Tracking_ID = "UA-249193041-2";
ReactGA.initialize(Tracking_ID);




const App = ()=>{
    

    const getTheme = () =>{return(JSON.parse(localStorage.getItem("DARK_MODE"))||false)}
    const [darkMode, setDarkMode] = useState(getTheme());
    const [loading,setLoading] = useState(false);
    const [search,setSearch] = useState("");
    const {currentUser} = useContext(AuthContext);
    

    const [categories, setCategories] = useState([]);
    //get all categories
    useEffect(()=>{
      setLoading(true);
      const q = query(collection(db, 'categories'), orderBy('timestamp','desc'));
      const unSub = onSnapshot(q, (snapshot)=>{
        let list = [];
        snapshot.docs.forEach((doc)=>{
          list.push({id:doc.id, ...doc.data()})
        });
        setCategories(list);
        console.log(categories)
        setLoading(false);
      },(error)=>{console.log(error)})
      return () => {unSub()};
    },[])

    useEffect(()=>{
      setLoading(true);
      setTimeout(()=>{
        setLoading(false)
      },500)
    },[])

    useEffect(() => {
      console.log(`Is in dark mode? ${darkMode}`);
      localStorage.setItem("DARK_MODE",JSON.stringify(darkMode));  
    },[darkMode]);

    const ReqAuth = ({children}) => {
      return currentUser?children:<Navigate to="/login" />
    }

    const theme = createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light'
        // mode: 'dark',
        },
        overrides: {
            MuiCssBaseline: {
              '@global': {
                body: {
                  transition: 'all 0.5s linear',
                },
              },
            },
          },
    });


    
  
    //none interaction event
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])
  
    return(
 
            <ThemeProvider theme={theme}>
                <Search setSearch={setSearch} setDarkMode={setDarkMode} darkMode={darkMode}/>
                <CssBaseline />
                <Paper className="parent__container" variant="outlined" square style={{ transition: 'var(--transition)' }}>
                {/* <Avatar /> */}
               
                
                {/* <Navigation setSearch={setSearch}/> */}
                <Sidebar />
                
                <Testimonial darkMode={darkMode} />
                {loading?<ScreenLoader darkMode={darkMode}/>:''}
                
                <Categories darkMode={darkMode}/>
                <Routes>
                    <Route path="login" element={<Login darkMode={darkMode} />} />
                    <Route path="admin" element={<ReqAuth darkMode={darkMode}><Admin darkMode={darkMode} /></ReqAuth>} />
                    <Route path="admin/update/:id" element={<ReqAuth darkMode={darkMode}><Admin darkMode={darkMode} /></ReqAuth>}></Route>
                    
                    <Route path="/" element={<Product loading={loading} search={search} darkMode={darkMode} />} />
                    {categories.map((category)=>(
                      <Route path={encodeURIComponent(category.category_title.replace(/\s/g, '').toLowerCase())} element={<Product search={search} darkMode={darkMode} loading={loading}  />} />
                    ))}
                    {/* <Route path="ADV" element={<Product search={search} darkMode={darkMode} loading={loading}  />} />
                    <Route path="Click160i" element={<Product search={search} darkMode={darkMode} />} />
                    <Route path="Scoopy" element={<Scoopy />} darkMode={darkMode} />
                    <Route path="PCX" element={<Scoopy />} darkMode={darkMode} />
                    <Route path="aerox" element={<Scoopy />} darkMode={darkMode} />
                    <Route path="Winner X" element={<Scoopy />} darkMode={darkMode} /> */}

                    <Route path="/search" element={<Product search={search} darkMode={darkMode} />} />
                    <Route path="/search/:q" element={<Product search={search} darkMode={darkMode} />} />

                </Routes>
                </Paper>
            </ThemeProvider>
  
    )
}




export default App;





