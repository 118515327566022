import React from 'react'
import "./admin.css"
import {BiEdit, BiTrashAlt} from "react-icons/bi"
import { useParams,useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ScreenLoader from './ScreenLoader';




//firebase
import { db, storage } from '../firebase';
import { uploadBytesResumable,ref, getDownloadURL, getStorage, uploadString } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc, onSnapshot, orderBy, query, deleteDoc } from 'firebase/firestore';

//mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import { clickData } from './product-data';
// import { Skeleton } from '@mui/material';
import Skeleton from 'react-loading-skeleton';



// import TESTIMONIAL1 from "../assets/images/20220920_091731.jpg"
// import TESTIMONIAL2 from "../assets/images/20220920_211841.jpg"
// import TESTIMONIAL3 from "../assets/images/received_3273393542980414.jpeg"
// import TESTIMONIAL4 from "../assets/images/received_632340134900365.jpeg"
// import TESTIMONIAL5 from "../assets/images/IMG_20221003_181355_272 copy 2.jpg"
// import TESTIMONIAL6 from "../assets/images/20220920_091554 (2).png" 
// import TESTIMONIAL7 from "../assets/images/received_914473629512766.jpeg"
// import TESTIMONIAL8 from "../assets/images/green_click.jpeg"
// // import TESTIMONIAL9 from "../assets/images/photo_2022-10-06 19.46.52.jpeg"
// import TESTIMONIAL10 from "../assets/images/photo_2022-10-06 19.46.48.jpeg"
// import TESTIMONIAL11 from "../assets/images/clickred.jpg"
// import TESTIMONIAL12 from "../assets/images/click-new"
// import TESTIMONIAL13 from "../assets/images/click-new1"
// import TESTIMONIAL14 from "../assets/images/click-new2"
// import TESTIMONIAL15 from "../assets/images/clicknew.jpg"
// import TESTIMONIAL16 from "../assets/images/click160i-img/160cbs.png"
import { TiDelete } from "react-icons/ti";
import { FaStar } from "react-icons/fa";
import { plPL } from '@mui/x-data-grid';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const initializeState = {
  title: '',
  price: '',
  discount: '',
  delivery: '',
  slug:'',
  preorder: false,
}

const categoryState = {
  category_title: '',
  category_image: ''
}



const imageSchemas = {
  title: ''
}

const Admin = ({darkMode}) => {
  const [product, setProduct] = useState([]);
  const [deleteProduct, setDeleteProduct] = useState({});
  const [deleteTestimonial, setDeleteTestimonial] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initializeState);
  const {img: productImage} = data
  const {title,price,discount,delivery,slug} = data;
  const [file, setFile] = useState(null);
  const [imageSchema, setImageSchema] = useState(imageSchemas);
  const [testimonialImage, setTestimonialImage] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const [progress, setProgress] = useState(null); 
  const [errors, setErrors] = useState(null);
  const [isSubmit, setIsSubmit] = useState(null);
  const [success, setSucess] = useState(false);
  const {id} = useParams();
  const navigate = useNavigate();
  const [categoriesData, setCategoriesData] = useState(categoryState);
  const {category_title, category_image} = categoriesData;
  const [categories, setCategories] = useState([]);
  const [categoryImage, setCategoryImage] = useState(null);
  const [deleteCategory, setDeleteCategory] = useState({});

  const [images, setImages] = useState([]);
  const [downloadUrls, setDownloadUrls] = useState([]);
  const [urls, setUrls] = useState([]);



  useEffect(() => {
    id && getSingleProduct(); 
    setInterval(() => {
      setLoading(true);
    }, 600);
  },[id])

  const getSingleProduct = async () => {
    const docRef = doc(db, 'products', id);
    const snapShot = await getDoc(docRef);
    if (snapShot.exists()){
      setData({...snapShot.data()});
    }
  }

  // useEffect(()=>{
  //   const uploadFile = () => {
  //     const name = new Date().getTime() + file.name;
  //     console.log(file)
  //     const storageRef = ref(storage, file.name);
  //     const uploadTask = uploadBytesResumable(storageRef, file);
  //     // setLoading(true);

  //     uploadTask.on("state_changed", (snapshot) => {
  //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       setProgress(progress);
  //       switch (snapshot.state) {
  //         case "paused":
  //           console.log("upload is paused");
  //         case 'running':
  //          console.log("Upload is running");
  //         default:
  //           break;
  //       }
  //     },(error)=>{
  //       console.log(error)
  //     },() => {
  //       getDownloadURL(uploadTask.snapshot.ref)
  //       .then((downloadURL)=>{
  //         setData((prev) => ({...prev, img:downloadURL}))
  //       })
  //     }
  //     );
  //   };
  //   file && uploadFile();
  // },[file])
  useEffect(() => {
    const uploadFile = async () => {
      const downloadURLs = [...downloadUrls];

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const storageRef = ref(storage, `/imgFiles/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        setLoading(true);
        setProgress(progress);
        uploadTask.on("state_changed", (snapshot) => {
          const newProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(newProgress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        }, (error) => {
          console.log(error);
        }, async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            downloadURLs.push(downloadURL);
            setDownloadUrls(downloadURLs);      
          } catch (error) {
            console.log(error);
          } finally {
            setImages([]);
            setLoading(false);
          }
        });
      }
    };

    if (images.length > 0) {
      uploadFile();
    }

  }, [images]);
  console.log(downloadUrls)
  console.log(data)

  // useEffect(()=>{setData((prev) => ({...prev, img:downloadUrls})) },[downloadUrls])
  useEffect(() => {
    setData((prev) => {
      let updatedImg;
  
      // If prev.img is an array, use it; otherwise, convert prev.img to an array
      const existingImages = Array.isArray(prev.img) ? prev.img : [prev.img];
  
      // Filter out any new images that are already present in the existing images array
      const newImages = Array.isArray(downloadUrls) ? downloadUrls.filter(url => !existingImages.includes(url)) : [downloadUrls];
  
      // Merge existing images with new images
      updatedImg = [...existingImages, ...newImages];
  
      return { ...prev, img: updatedImg };
    });
  }, [downloadUrls]);

  const handleImageChange = (e) => {
    const selectedFiles = e.target.files;
    setImages([...selectedFiles]);
};

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  }

  const validate = () => {
    let errors ={};
    if(!title){
      errors.title ="title is required,";
    }
    if(!price){
      errors.price = "price is required,";
    }
    // if(!discount){
    //   errors.discount = "discount is required,";
    // }
    if(!delivery){
      errors.delivery = "delivery is required,";
    }
    if(!slug){
      errors.slug ="slug is required,";
    }

    return errors;

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);
  
    //starting of data upload
    setIsSubmit(true);
    if(!id){
      try{
        await addDoc(collection(db,"products"), {...data, timestamp: serverTimestamp(),});
        console.log("products added successfully")
      }catch(err){
        console.log(err);
      }
    } else {
      try{
        await updateDoc(doc(db,"products", id), {...data, timestamp: serverTimestamp(),});
        console.log("product updated successfully")
        navigate("/admin#")
      }catch(err){
        console.log(err);
      }
    }
    window.location.reload();
    
    navigate("/admin#productlist")
  }

  useEffect(()=>{
    setLoading(true);
    // collection(db,"products"),orderBy("timeStamp")
    const q = query(collection(db, 'products'), orderBy('timestamp','desc'));
    const unSub = onSnapshot(q, (snapshot)=>{
      let list = [];
      snapshot.docs.forEach((doc)=>{
        list.push({id:doc.id, ...doc.data()})
      });
      setProduct(list);
      setLoading(false);
    },(error)=>{console.log(error)})
    return () => {unSub()};
  },[])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSucess(false);
  };

  const handleUpdate = (id) => {
    navigate(`/admin/update/${id}`);
  }

  const handleDelete = async (id) => {
    if(window.confirm("Are you sure you want to delete this Product?")){
      try{
        setDeleteProduct(id)
        await deleteDoc(doc(db,"products",id));
        setSucess(true);
        setProduct(product.filter(deleteProduct => deleteProduct.id !== id));

      }catch(err){
        console.log(err)
      }
    }
  }

  useEffect(()=>{
    const uploadTestimonialImage = () => {
      const name = new Date().getTime() + testimonialImage.name;
      console.log(testimonialImage)
      const storageRef = ref(storage, testimonialImage.name);
      const uploadTask = uploadBytesResumable(storageRef, testimonialImage);
      // setLoading(true);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("upload is paused");
          case 'running':
           console.log("Upload is running");
          default:
            break;
        }
      },(error)=>{
        console.log(error)
      },() => {
        getDownloadURL(uploadTask.snapshot.ref)
        .then((downloadURL)=>{
          setImageSchema((prev) => ({...prev, img:downloadURL}))
          console.log(imageSchemas)
          console.log("khob sari")    
        })
      }
      );
    };
    testimonialImage && uploadTestimonialImage();
  },[testimonialImage])

  const handleTestimonialSubmit = async (e) => {
    e.preventDefault();

    //starting of data upload
    setIsSubmit(true);
    if(!id){
      try{
        await addDoc(collection(db,"testimonials"), {...imageSchema, timestamp: serverTimestamp(),});
        console.log("testimonial added successfully")
      }catch(err){
        console.log(err);
      }
    } else {
      try{
        await updateDoc(doc(db,"testimonials", id), {...imageSchema, timestamp: serverTimestamp(),});
        console.log("testimonial updated successfully")
        navigate("/admin")
      }catch(err){
        console.log(err);
      }
    }
    window.location.reload();
    
    navigate("/admin#testimonialList")
  }

  const handleTestimonialUpdate = (id) => {
    navigate(`/admin/update/${id}`);
  }

  const handleTestimonialDelete = async (id) => {
    if(window.confirm("Are you sure you want to delete this Testimonial Image?")){
      try{
        setDeleteTestimonial(id)
        await deleteDoc(doc(db,"testimonials",id));
        setSucess(true);
        setImageSchema(imageSchema.filter(deleteTestimonial => deleteTestimonial.id !== id));

      }catch(err){
        console.log(err)
      }
    }
  }

  useEffect(() => {
    id && getSingleTestimonial(); 
    setInterval(() => {
      setLoading(true);
    }, 600);
  },[id])

  const getSingleTestimonial = async () => {
    const docRef = doc(db, 'testimonials', id);
    const snapShot = await getDoc(docRef);
    if (snapShot.exists()){
      setImageSchema({...snapShot.data()});
    }
  }

  useEffect(()=>{
    setLoading(true);
    const q = query(collection(db, 'testimonials'), orderBy('timestamp','desc'));
    const unSub = onSnapshot(q, (snapshot)=>{
      let list = [];
      snapshot.docs.forEach((doc)=>{
        list.push({id:doc.id, ...doc.data()})
      });
      setTestimonials(list);
      setLoading(false);
    },(error)=>{console.log(error)})
    return () => {unSub()};
  },[])


  //categories

  const handleCategoryChange = (e) => {
    setCategoriesData({...categoriesData, [e.target.name]: e.target.value});
  }

  const handleCategorySubmit = async (e) => {
    e.preventDefault();

    //starting of data upload
    setIsSubmit(true);
    if(!id){
      try{
        await addDoc(collection(db,"categories"), {...categoriesData, timestamp: serverTimestamp(),});
        console.log("category added successfully")
      }catch(err){
        console.log(err);
      }
    } else {
      try{
        await updateDoc(doc(db,"categories", id), {...categoriesData, timestamp: serverTimestamp(),});
        console.log("category updated successfully")
        navigate("/admin")
      }catch(err){
        console.log(err);
      }
    }
    window.location.reload();
    
    navigate("/admin#testimonialList")
  }

  //get all categories
  useEffect(()=>{
    setLoading(true);
    const q = query(collection(db, 'categories'), orderBy('timestamp','desc'));
    const unSub = onSnapshot(q, (snapshot)=>{
      let list = [];
      snapshot.docs.forEach((doc)=>{
        list.push({id:doc.id, ...doc.data()})
      });
      setCategories(list);
      console.log(categories)
      setLoading(false);
    },(error)=>{console.log(error)})
    return () => {unSub()};
  },[])

  //get single category
  useEffect(() => {
    id && getSingleCategory(); 
    setInterval(() => {
      setLoading(true);
    }, 600);
  },[id])

  const getSingleCategory = async () => {
    const docRef = doc(db, 'categories', id);
    const snapShot = await getDoc(docRef);
    if (snapShot.exists()){
      console.log({...snapShot.data()})
      setCategoriesData({...snapShot.data()});
      console.log("exist")
    }
  }

  useEffect(()=>{
    const uploadCategoryImage = () => {
      const name = new Date().getTime() + categoryImage.name;
      const storageRef = ref(storage, categoryImage.name);
      const uploadTask = uploadBytesResumable(storageRef, categoryImage);
      // setLoading(true);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("upload is paused");
          case 'running':
           console.log("Upload is running");
          default:
            break;
        }
      },(error)=>{
        console.log(error)
      },() => {
        getDownloadURL(uploadTask.snapshot.ref)
        .then((downloadURL)=>{
          setCategoriesData((prev) => ({...prev, category_image:downloadURL}))
        })
      }
      );
    };
    categoryImage && uploadCategoryImage();
  },[categoryImage])

  const handleCategoryDelete = async (id) => {
    if(window.confirm("Are you sure you want to delete this Category?")){
      try{
        setDeleteCategory(id)
        await deleteDoc(doc(db,"categories",id));
        setSucess(true);
        setCategories(categories.filter(deleteCategory => deleteCategory.id !== id));
      }catch(err){
        console.log(err)
      }
    }
  }


  // const imageData = [{img:TESTIMONIAL1},{img:TESTIMONIAL10},{img:TESTIMONIAL11},{img:TESTIMONIAL12},{img:TESTIMONIAL13},{img:TESTIMONIAL14},{img:TESTIMONIAL15},{img:TESTIMONIAL16},{img:TESTIMONIAL2},{img:TESTIMONIAL3},{img:TESTIMONIAL4},{img:TESTIMONIAL5},{img:TESTIMONIAL6},{img:TESTIMONIAL7},{img:TESTIMONIAL8},{img:TESTIMONIAL9}]

  const deleteImage = (index) => {
    console.log(index)
    setData(prevData => {
      const updatedProductImage = prevData.img.filter((_, i) => i != index);
      console.log(updatedProductImage)
      return { ...prevData, img: updatedProductImage };
    });
  }

  const setStarImage = (index) => {
    setData(prevData => {
      const selectedImage = prevData.img[index];
      const updatedProductImage = prevData.img.filter((_, i) => i !== index);
      updatedProductImage.unshift(selectedImage);
      return { ...prevData, img: updatedProductImage };
    });
  };

  return (
    <section className="admin__section">
      <h4 id="productlist" className=''>Product List</h4>
      <ul className="product__table">
       {!loading?<Skeleton baseColor={darkMode?'var(--third-color)':null} height={300} />:product.map((p)=>(
          <li key={p.id} className="table__element" style={darkMode?{background:"#232323"}:null}>
            <img src={p.img} alt="" />
            <p>{p.title}</p>
            <p style={{flex:"1 1 0%"}}>{p.price}</p>
            <span>{p.delivery}</span>
            <div className="option__wrapper">
              <span onClick={()=>{handleUpdate(p.id)}} className='update__element'><BiEdit /></span>
              <span onClick={()=>{handleDelete(p.id)}} className="delete__element"><BiTrashAlt /></span>
            </div>
        </li>
       ))}
      </ul>

      <div className='flex'>
      {isSubmit?(<ScreenLoader darkMode={darkMode}/>):
      <div className='flex flex-col gap-4 w-full'>
        <h4 className='update-add__label'>{id?"Update Product":"Add Product"}</h4>

        <form onSubmit={handleSubmit} action='' className='flex flex-col text-sm text-slate-500 gap-1 form__controller'>

        <div className="input__wrapper">
            <label htmlFor="" className='mt-4'>Product Name</label>
            <input 
              style={darkMode?{background:"#232323",color:"#fff"}:null}
              name='title' 
              type='text' 
              placeholder='product name' 
              onChange={handleChange} 
              value={title}
              className='rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2'
            />
        </div>

        <div className="input__wrapper">
          <label htmlFor="" className='mt-4'>Price</label>
          <input 
            style={darkMode?{background:"#232323",color:"#fff"}:null}
            name='price' 
            type='text' 
            placeholder='price'  
            onChange={handleChange} 
            value={price}
            className='rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2'
          />
        </div>

        
        <div className="input__wrapper">
          <label htmlFor="" className='mt-4'>Initial Price</label>
          <input 
            style={darkMode?{background:"#232323",color:"#fff"}:null}
            name='discount' 
            type='text' 
            placeholder='discount'  
            onChange={handleChange} 
            value={discount}
            className='rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2'
          />
        </div>
       
        <div className="input__wrapper">
        <label htmlFor="" className='mt-4'>Delivery</label>
          <input 
            style={darkMode?{background:"#232323",color:"#fff"}:null}
            name='delivery' 
            type='text' 
            placeholder='delivery price'  
            onChange={handleChange} 
            value={delivery}
            className='rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2'
          />
        </div>
        
        <div className="input__wrapper">
          <label htmlFor="" className='mt-4'>Slug</label>
          <input 
            style={darkMode?{background:"#232323",color:"#fff"}:null}
            name='slug' 
            type='text' 
            placeholder='slug'  
            onChange={handleChange} 
            value={slug}
            className='rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2'
          />
        </div>

        <div className="checkbox__wrapper">
        <p htmlFor="" className='mt-4'>IsPreorder</p>
        <input 
          style={darkMode ? { background: "#232323", color: "#fff" } : null}
          name='preorder' 
          type='checkbox'
          onChange={(e) => setData({ ...data, preorder: e.target.checked })}
          checked={data.preorder}
          className='rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2'
        />
      </div>
        
        <div className="input__wrapper">
          <label htmlFor="" className='mt-4'>Upload File</label>
          <input style={darkMode?{background:"#232323"}:null} type='file' multiple onChange={handleImageChange} className='' />
        </div>
    

        <span className={`text-red-400`}>{errors && (errors.title +'  '+ errors.price +' '+ errors.discount +' '+errors.delivery+'  '+errors.slug) }</span>
        {progress !== null && progress < 100?<Skeleton darkMode={darkMode} baseColor={darkMode?'var(--third-color)':null} width={300} height={150} />:
          productImage && (
            Array.isArray(productImage) ? (
              // If productImage is an array of image URLs
              <div style={{display:"flex",flexDirection:"row",gap:"1rem",position:"relative", width:"500px", overflow:"scroll"}}>
              {productImage.map((imageUrl, index) => (
                  <span style={{position:"relative"}} className="">
                    <img key={index} src={imageUrl} alt="" className={`disp__image mt-4 rounded-md transition-all`} />
                    <span onClick={(e)=>{e.preventDefault();deleteImage(index)}}><TiDelete style={{fontSize:"2rem",position:"absolute",top:0,right:0}} /></span>
                    <span onClick={(e)=>{e.preventDefault();setStarImage(index)}}><FaStar style={{fontSize:"1.5rem",position:"absolute",top:".2rem",left:".2rem"}} /></span>
                  </span>
              ))}
              </div>
            ) : (
              // If productImage is a single image URL
              <img src={productImage} alt="" className={`disp__image mt-4 rounded-md transition-all`} />
            )
          )
        }
        {/* {
          productImage && (
            Array.isArray(productImage) ? (
              // If productImage is an array of image URLs
              <div style={{display:"flex",flexDirection:"row",gap:"1rem",position:"relative", width:"500px", overflow:"scroll"}}>
              {productImage.map((imageUrl, index) => (
                  <span style={{position:"relative"}} className="">
                    <img key={index} src={imageUrl} alt="" className={`disp__image mt-4 rounded-md transition-all`} />
                    <span onClick={(e)=>{e.preventDefault();deleteImage(index)}}><TiDelete style={{fontSize:"2rem",position:"absolute",top:0,right:0}} /></span>
                    <span onClick={(e)=>{e.preventDefault();setStarImage(index)}}><FaStar style={{fontSize:"1.5rem",position:"absolute",top:".2rem",left:".2rem"}} /></span>
                  </span>
              ))}
              </div>
            ) : (
              // If productImage is a single image URL
              <img src={productImage} alt="" className={`disp__image mt-4 rounded-md transition-all`} />
            )
          )
        } */}


        <Stack direction="row" spacing={2}  justifyContent="center">
          <button 
            style={darkMode?{background:"#232323",color:"var(--third-color)"}:{background:"var(--third-color)",}}
            type="submit"
            disabled={progress !== null && progress < 100}
            className={`btn mb-4 disabled:opacity-25 disabled:cursor-not-allowed cursor-pointer mx-auto mt-4 p-2 rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]`}>
            <p>{id?"Update Product":"Add Product"}</p>
          </button>
          {id?<button style={darkMode?{background:"#232323",color:"var(--third-color)"}:{background:"var(--third-color)",}} onClick={()=>{navigate("/admin");window.location.reload()}} className='btn'>go back to Add Product</button>:null}
        </Stack>
       
      </form>

      {/* <button onClick={()=>{addDocumentsToFirestore(clickData);}}>execute</button> */}
      <Snackbar open={success} autoHideDuration={6000} sx={{ zIndex: 'tooltip' }} onClose={handleClose}>
        <Alert onClose={handleClose}  severity="error" sx={{ width: '100%', zIndex: 'tooltip' }}>
          Product Deleted Sucessfully
        </Alert>
      </Snackbar>
    </div>
   }
    </div>
   {/* Testimonials */}
    <h4 id="testimonialList" className=''>Testimonials List</h4>
      <ul className="product__table">
       {!loading?<Skeleton baseColor={darkMode?'var(--third-color)':null} height={300} />:testimonials.map((t)=>(
          <li key={t.id} className="table__element" style={darkMode?{background:"#232323"}:null}>
            <img src={t.img} alt="" />
            <p>{t.id}</p>
            <p style={{flex:"1 1 0%"}}></p>
            <div className="option__wrapper">
              <span onClick={()=>{handleTestimonialUpdate(t.id)}} className='update__element'><BiEdit /></span>
              <span onClick={()=>{handleTestimonialDelete(t.id)}} className="delete__element"><BiTrashAlt /></span>
            </div>
        </li>
       ))}
      </ul>

      <div className='flex'>
        {isSubmit?(<ScreenLoader darkMode={darkMode}/>):
        <div className='flex flex-col gap-4 w-full'>
          <h4 className='update-add__label'>{id?"Update Testimonials":"Add Testimonials"}</h4>

          <form onSubmit={handleTestimonialSubmit} action='' className='flex flex-col text-sm text-slate-500 gap-1 form__controller'>
          
          <div className="input__wrapper">
            <label htmlFor="" className='mt-4'>Upload Testimonial Image</label>
            <input style={darkMode?{background:"#232323"}:null} type='file' onChange={(e)=> setTestimonialImage(e.target.files[0])} className='' />
          </div>

          {/* <span className={`text-red-400`}>{errors && (errors.title +'  '+ errors.price +' '+ errors.discount +' '+errors.delivery+'  '+errors.slug) }</span> */}
          {progress !== null && progress < 100?<Skeleton darkMode={darkMode} baseColor={darkMode?'var(--third-color)':null} width={300} height={150} />:<img src={imageSchema.img} alt="" className={`disp__image mt-4 rounded-md transition-all`} />}
            {/* <button onClick={(e)=>{e.preventDefault();uploadImageDataToFirestore(imageData);}}>execute</button> */}
          <Stack direction="row" spacing={2}  justifyContent="center">
            <button 
              style={darkMode?{background:"#232323",color:"var(--third-color)"}:{background:"var(--third-color)",}}
              type="submit"
              disabled={progress !== null && progress < 100}
              className={`btn mb-4 disabled:opacity-25 disabled:cursor-not-allowed cursor-pointer mx-auto mt-4 p-2 rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]`}>
              <p>{id?"Update Testimonial":"Add Testimonial"}</p>
            </button>
            {id?<button style={darkMode?{background:"#232323",color:"var(--third-color)"}:{background:"var(--third-color)",}} onClick={()=>{navigate("/admin");window.location.reload()}} className='btn'>go back to Add Testimonial</button>:null}
          </Stack>
        
        </form>

        {/* <button onClick={()=>{addDocumentsToFirestore(clickData);}}>execute</button> */}
        <Snackbar open={success} autoHideDuration={6000} sx={{ zIndex: 'tooltip' }} onClose={handleClose}>
          <Alert onClose={handleClose}  severity="error" sx={{ width: '100%', zIndex: 'tooltip' }}>
            Testimonials Deleted Sucessfully
          </Alert>
        </Snackbar>
      </div>
    }
    </div>

    {/* category */}
    <h4 id="testimonialList" className=''>Categories List</h4>
      <ul className="product__table">
       {!loading?<Skeleton baseColor={darkMode?'var(--third-color)':null} height={300} />:categories.map((t)=>(
          <li key={t.id} className="table__element" style={darkMode?{background:"#232323"}:null}>
            <img src={t.category_image} alt="no image" />
            <p>{t.category_title}</p>
            <p style={{flex:"1 1 0%"}}></p>
            <div className="option__wrapper">
              <span onClick={()=>{handleTestimonialUpdate(t.id)}} className='update__element'><BiEdit /></span>
              <span onClick={()=>{handleCategoryDelete(t.id)}} className="delete__element"><BiTrashAlt /></span>
            </div>
        </li>
       ))}
      </ul>

      <div className='flex'>
        {isSubmit?(<ScreenLoader darkMode={darkMode}/>):
        <div className='flex flex-col gap-4 w-full'>
          <h4 className='update-add__label'>{id?"Update Categories":"Add Categories"}</h4>

          <form onSubmit={handleCategorySubmit} action='' className='flex flex-col text-sm text-slate-500 gap-1 form__controller'>
          <div className="input__wrapper">
            <label htmlFor="" className='mt-4'>Category Name</label>
            <input 
              style={darkMode?{background:"#232323",color:"#fff"}:null}
              name='category_title' 
              type='text' 
              placeholder='category name' 
              onChange={handleCategoryChange} 
              value={category_title}
              className='rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] p-2'
            />
        </div>

          <div className="input__wrapper">
            <label htmlFor="" className='mt-4'>Upload Category Image</label>
            <input style={darkMode?{background:"#232323"}:null} type='file' onChange={(e)=> setCategoryImage(e.target.files[0])} className='' />
          </div>

          {/* <span className={`text-red-400`}>{errors && (errors.title +'  '+ errors.price +' '+ errors.discount +' '+errors.delivery+'  '+errors.slug) }</span> */}
          {progress !== null && progress < 100?<Skeleton darkMode={darkMode} baseColor={darkMode?'var(--third-color)':null} width={300} height={150} />:<img src={categoriesData.category_image} alt="" className={`disp__image mt-4 rounded-md transition-all`} />}
            {/* <button onClick={(e)=>{e.preventDefault();uploadImageDataToFirestore(imageData);}}>execute</button> */}
          <Stack direction="row" spacing={2}  justifyContent="center">
            <button 
              style={darkMode?{background:"#232323",color:"var(--third-color)"}:{background:"var(--third-color)",}}
              type="submit"
              disabled={progress !== null && progress < 100}
              className={`btn mb-4 disabled:opacity-25 disabled:cursor-not-allowed cursor-pointer mx-auto mt-4 p-2 rounded-md shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]`}>
              <p>{id?"Update Category":"Add Category"}</p>
            </button>
            {id?<button style={darkMode?{background:"#232323",color:"var(--third-color)"}:{background:"var(--third-color)",}} onClick={()=>{navigate("/admin");window.location.reload()}} className='btn'>go back to Add Category</button>:null}
          </Stack>
        
        </form>

        {/* <button onClick={()=>{addDocumentsToFirestore(clickData);}}>execute</button> */}
        <Snackbar open={success} autoHideDuration={6000} sx={{ zIndex: 'tooltip' }} onClose={handleClose}>
          <Alert onClose={handleClose}  severity="error" sx={{ width: '100%', zIndex: 'tooltip' }}>
            Category Deleted Sucessfully
          </Alert>
        </Snackbar>
      </div>
    }
    </div>
      
    </section>
  )
}

export default Admin