import React from 'react'
import { useState, useContext } from 'react';

import { useNavigate } from "react-router-dom";
import {AuthContext} from "./context/AuthContext"
import {signInWithEmailAndPassword } from "firebase/auth";
import "./login.css"
import {auth} from "../firebase"
import ScreenLoader from './ScreenLoader';

const Login = ({darkMode}) => {
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate();
  
    const {dispatch} = useContext(AuthContext);
    const handleLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          dispatch({type:"LOGIN", payload:user});
          console.log(user)
          navigate("/admin")
        })
        .catch((error) => {
          setError(true)     
        });
      }

  return (
    <div className='form__container' style={darkMode?{background:"#121212"}:null}>
        <form onSubmit={handleLogin} action="" className="form" >
            <div>
                <label htmlFor="">Username</label>
                <input type="text" placeholder='username' style={darkMode?{background:"#383838",color:"#fff"}:null} onChange={e=>setEmail(e.target.value)} />
            </div>
            <div>
                <label htmlFor="">Password</label>
                <input type="password" placeholder='password' style={darkMode?{background:"#383838",color:"#fff"}:null} onChange={e=>setPassword(e.target.value)} />
            </div>
   
            <button type="submit" style={darkMode?{background:"#383838",color:"#fff"}:null}>Login</button>
            {error && <span>Invalid credential!</span>}
        </form>
    </div>
  )
}

export default Login