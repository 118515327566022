import React,{useState,useEffect} from 'react'
import "./products/navigation/navigation.css"
import {FaSearch} from "react-icons/fa"
import {GoThreeBars} from "react-icons/go"
import CustomizedSwitches from './New'
import { Paper } from '@mui/material'
import {HiMenuAlt1} from "react-icons/hi"
import { useContext } from 'react'
import { SidebarContext } from './context/sidebar-context'
import { useNavigate } from 'react-router-dom';
import { TbMoonStars } from "react-icons/tb";
import { FiSun } from "react-icons/fi";




const Search = ({setSearch,darkMode,setDarkMode}) => {
  
  const toggleDarkMode = ()=>{setDarkMode(!darkMode)}
  const {open , setOpen} = useContext(SidebarContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  return (
  //   <nav className="input__container" style={darkMode?{background:"#121212"}:null}>
  //   <div className='toggler__container'>
  //     {/* <GoThreeBars className='toggler__icon'/> */}
  //     <div className='nav-icon__container' onClick={()=>{setOpen(!open)}} style={darkMode?{color:"var(--third-color"}:null}><HiMenuAlt1 /></div>
  //     <div className='logo' style={darkMode?{color:"var(--third-color"}:null}><h3>Lyath Sticker Shop</h3></div>
  //     <form action="" className='search__form-lg'>
  //     <button style={darkMode?{background:'#252525',color:"var(--third-color"}:null} type="" className='search__btn' onClick={(e)=>{e.preventDefault()}}><FaSearch /></button>
  //     <input style={darkMode?{background:'#252525',color:"#ffffff",border:"1px solid #232323"}:null} type="search" className="search" placeholder='ស្វែងរកផលិតផល...' onChange={(e)=>{setLoading(true);navigate(`/search/${e.target.value}`)}}/>
  //     </form>
  //     <button className='toggler__icon' onClick={toggleDarkMode}><CustomizedSwitches darkMode={darkMode} /></button>

  //   </div>
  //   <form action="" className='search__form'>
  //     <button style={darkMode?{background:'#252525',color:"var(--third-color"}:null} type="" className='search__btn' onClick={(e)=>{e.preventDefault()}}><FaSearch /></button>
  //     <input style={darkMode?{background:'#252525',color:"#ffffff",border:"1px solid #232323"}:null} type="search" className="search" placeholder='ស្វែងរកផលិតផល...' onChange={(e)=>{setLoading(true);navigate(`/search/${e.target.value}`)}}/>
  //   </form>
  // </nav>
      <nav className="input__container" style={darkMode?{background:"#232323"}:null}>
      <div className='toggler__container'>
        {/* <GoThreeBars className='toggler__icon'/> */}
        <div className='nav-icon__container' onClick={()=>{setOpen(!open)}} style={darkMode?{color:"var(--third-color"}:null}><HiMenuAlt1 /></div>
        <div className='logo' style={darkMode?{color:"var(--third-color"}:null}><h3>Lyath Sticker Shop</h3></div>
        <form action="" className='search__form-lg' style={darkMode?{background:'#000'}:null}>
        <button style={darkMode?{background:'#252525',color:"var(--third-color"}:null} type="" className='search__btn' onClick={(e)=>{e.preventDefault()}}><FaSearch style={{fontSize:"1rem"}} /></button>
        <input style={darkMode?{background:'#252525',color:"#ffffff",border:"1px solid #232323"}:null} type="search" className="search" placeholder='ស្វែងរកផលិតផល...' onChange={(e)=>{setLoading(true);navigate(`/search/${e.target.value}`)}}/>
        </form>
        <button className='toggler__icon' onClick={toggleDarkMode}><CustomizedSwitches darkMode={darkMode} /></button>
        <button className="toggler__icon2" onClick={toggleDarkMode}>
          {!darkMode?<FiSun className="toggler__child" />:<TbMoonStars className="toggler__child" />}
        </button>
      </div>
      <form action="" className='search__form'>
        <button style={darkMode?{background:'#252525',color:"var(--third-color"}:null} type="" className='search__btn' onClick={(e)=>{e.preventDefault()}}><FaSearch /></button>
        <input style={darkMode?{background:'#252525',color:"#ffffff",border:"1px solid #232323"}:null} type="search" className="search" placeholder='ស្វែងរកផលិតផល...' onChange={(e)=>{setLoading(true);navigate(`/search/${e.target.value}`)}}/>
      </form>
    </nav>
  )
}

export default Search