import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ReactImageZoom from 'react-image-zoom';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



const Modal = ({open, setOpen, handleClickOpen, handleClose, title, image}) => {
  const props = {width: 400, height: 400, zoomPosition: "original", scale: 1, zoomWidth: 500, img: image};
  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle className="testimonial__title" sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <span className="product__title">{title}</span>
        </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          close
        </IconButton> */}
        <DialogContent style={{display:"flex",justifyContent:"center",alignItems:"center"}} dividers>
          {/* <img src={image} alt="" /> */}
          <ReactImageZoom {...props} style={{ zIndex: 1000 }} />
        </DialogContent>
        <DialogActions>
          <button className="buy__now" style={{border:"none",outline:"none",cursor:"pointer"}} autoFocus onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  )
}

export default Modal