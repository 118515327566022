import { initializeApp } from "firebase/app";
import {getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnrhhjAz7L5t0u0yogAi-fusUr-ZLL9LM",
  authDomain: "lyath-shop.firebaseapp.com",
  projectId: "lyath-shop",
  storageBucket: "lyath-shop.appspot.com",
  messagingSenderId: "292087517855",
  appId: "1:292087517855:web:bf70c634979339ee8a48aa"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
