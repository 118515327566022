import React,{useState,useEffect} from 'react'
import { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/autoplay/autoplay.min.css'
// import TESTIMONIAL1 from "../assets/images/20220920_091731.jpg"
// import TESTIMONIAL2 from "../assets/images/20220920_211841.jpg"
// import TESTIMONIAL3 from "../assets/images/received_3273393542980414.jpeg"
// import TESTIMONIAL4 from "../assets/images/received_632340134900365.jpeg"
// import TESTIMONIAL5 from "../assets/images/IMG_20221003_181355_272 copy 2.jpg"
// import TESTIMONIAL6 from "../assets/images/20220920_091554 (2).png" 
// import TESTIMONIAL7 from "../assets/images/received_914473629512766.jpeg"
// import TESTIMONIAL8 from "../assets/images/green_click.jpeg"
// import TESTIMONIAL9 from "../assets/images/photo_2022-10-06 19.46.52.jpeg"
// import TESTIMONIAL10 from "../assets/images/photo_2022-10-06 19.46.48.jpeg"
// import TESTIMONIAL11 from "../assets/images/clickred.jpg"
// import TESTIMONIAL12 from "../assets/images/click-new"
// import TESTIMONIAL13 from "../assets/images/click-new1"
// import TESTIMONIAL14 from "../assets/images/click-new2"
// import TESTIMONIAL15 from "../assets/images/clicknew.jpg"
// import TESTIMONIAL16 from "../assets/images/click160i-img/160cbs.png"
import "./testimonial.css"
import {BsFillSuitHeartFill} from "react-icons/bs"
import Search from './Search'

//firebase
import { db, storage } from '../firebase';
import { uploadBytesResumable,ref, getDownloadURL, getStorage, uploadString } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc, onSnapshot, orderBy, query, deleteDoc } from 'firebase/firestore';

//mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import { clickData } from './product-data';
import Skeleton from 'react-loading-skeleton'


const Testimonial = ({darkMode}) => {

  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    const q = query(collection(db, 'testimonials'), orderBy('timestamp','desc'));
    const unSub = onSnapshot(q, (snapshot)=>{
      let list = [];
      snapshot.docs.forEach((doc)=>{
        list.push({id:doc.id, ...doc.data()})
      });
      setTestimonials(list);
      setLoading(false);
    },(error)=>{console.log(error)})
    return () => {unSub()};
  },[])

  return (
    <div>
      <h3 className="testimonial__title"><span>Feedback</span> របស់ភ្ញៀវ <BsFillSuitHeartFill /></h3>
      {/* <Search setSearch={setSearch}/> */}
      <Swiper 
        className="mySwiper image__container"
        spaceBetween={0}
        slidesPerView={"1.25"}
        centeredSlides= {true}
        loop={true}
        speed= {700}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {loading?<SwiperSlide style={{borderRadius: "1rem"}} className="image__wrapper"><Skeleton className='testimonial__loader' baseColor={darkMode?'var(--third-color)':null} /></SwiperSlide>:testimonials.map((t)=>(
            <SwiperSlide key={t.id} className="image__wrapper"><img src={t.img} alt="" /></SwiperSlide>
        ))}

        {/* {loading?<SwiperSlide style={{borderRadius: "1rem"}} className="image__wrapper"><Skeleton className='testimonial__loader' baseColor={darkMode?'var(--third-color)':null} /></SwiperSlide>:testimonials.map((t)=>(
            <SwiperSlide key={t.id} className="image__wrapper">
            {t?.img?.includes('.mp4') ? (
                <video autoPlay muted loop>
                    <source src={t?.img} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (t?.img?.includes('.png') || t?.img?.includes('.jpeg') || t?.img?.includes('.jpg')) ? (
                <img src={t?.img} alt="" />
            ) : null}
        </SwiperSlide>
        
        ))} */}
        

        {/* {loading ? (
  <SwiperSlide style={{ borderRadius: "1rem" }} className="image__wrapper">
    <Skeleton
      className='testimonial__loader'
      baseColor={darkMode ? 'var(--third-color)' : null}
    />
  </SwiperSlide>
) : (
  testimonials.map((t) => (
    <SwiperSlide key={t.id} className="image__wrapper">
      {t?.img?.includes('.mp4') ? (
        <video autoPlay muted loop>
          <source src={t?.img} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (t?.img?.includes('.png') || t?.img?.includes('.jpeg') || t?.img?.includes('.jpg')) ? (
        <img src={t?.img} alt="" />
      ) : null}
    </SwiperSlide>
  ))
)} */}


      </Swiper>
    </div>
  )
}

export default Testimonial