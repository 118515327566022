import React,{useState,useEffect} from 'react'
import "./product.css"
import {clickData} from "./product-data"
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { maxHeight, maxWidth } from '@mui/system'
import IMG1 from '../assets/images/dummy.png'
import { LazyLoadImage } from "react-lazy-load-image-component";
import ScreenLoader from '../components/ScreenLoader'
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom'
import ReactImageFallback from "react-image-fallback";

//firebase
import { db, storage } from '../firebase';
import { uploadBytesResumable,ref, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc, onSnapshot, orderBy, query, deleteDoc, where, or, and } from 'firebase/firestore';
import Modal from './Modal'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import "swiper/swiper-bundle.min.css";
import { Pagination, Autoplay, Navigation } from 'swiper'

const Product = ({search,darkMode}) => {
    
    const [users, updateUsers] = useState(false);
    const [product, setProduct] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {q} = useParams();

    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(null);
    const [image, setImage] = useState(null);

    const handleClickOpen = (title,img) => {
      setOpen(true);
      setTitle(title);
      setImage(img);
    };
    const handleClose = () => {
      setOpen(false);
    };


    useEffect(()=>{
        setLoading(true);
        const productRef = collection(db, "products");

        const q = query(collection(db, 'products'), orderBy('timestamp',"desc"));
        const unSub = onSnapshot(q, (snapshot)=>{
          let list = [];
          snapshot.docs.forEach((doc)=>{
            list.push({id:doc.id, ...doc.data()})
          });
          setProduct(list);
          setLoading(false);
        },(error)=>{console.log(error)})
        return () => {unSub()};
      },[pathname])


    useEffect(() => {
        setTimeout(() => updateUsers(clickData), 1000)
      }, [clickData])

    //   useEffect(() => {
    //     console.log(window.location.pathname);   
    //   }, [])  
    const variant = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      };
    

      if (loading) {
        return (
            <div>
            <h3 className="product__catergory">{"សូមធ្វើការរងចាំ"} <span>...</span></h3>
    
            <div className="product__container">
            
                {[...Array(16)].map((id)=>{
                    return(
                       
                        <motion.artical variants={variant} initial="hidden" animate="visible" transition={{
                            delay: id * 0.25,
                            ease: "easeInOut",
                            duration: 0.5,
                          }}
                          viewport={{ amount: 0 }} style={darkMode?{background:"#232323"}:null} key={id} layout animate={{opacity:1}} initial={{opacity:0}} exit={{opacity:0}} className="product__card">
                        <div className="products__image skeleton__container" style={{position:"relative",width:"90%",margin:"auto",aspectRatio:"1/1"}}>
                            <img src={IMG1} style={{flex: "1 1 0%",visibility:"hidden", width:'100%',display:"block"}} alt="image" />  
                            <Skeleton containerClassName="flex-1" baseColor={darkMode?'var(--third-color)':null} style={{position:"absolute",width:'maxWidth',top:"0",bottom:"0"}}/>                 
                        </div>
                        <div className="product__spec">
                            <div className="product__spec-wrapper">
                               <Skeleton baseColor={darkMode?'var(--third-color)':null} count={2}/>
                               {/* <a className="buy__now" href="https://m.me/Lyath.Chhay">Buy Now</a> */}
                               <a className="buy__now" href="https://t.me/lyath11">Buy Now</a>
                            </div>
                        </div>
                </motion.artical> 
               
                    )
                })}
            </div>
        </div>
        );
      }


      const filtered = product.filter((value) => {
        
        //filtering keywords
        if (value.title.toLowerCase().includes(q && q.toLowerCase())) {
            return value;
        }
        //homepage
        if (window.location.pathname.substring(1).toLowerCase() == "" || window.location.pathname=="/search/"){
            return value.title.toLowerCase().includes("click") || value.title.toLowerCase().includes("");
        }
        //filter by brand category
        if (value.title.replace(/\s/g, '').toLowerCase().includes(window.location.pathname.substring(1).toLowerCase())) {    
            return value;     
        }
     
      });

      

      if(!filtered.length) return <div className="error"><p> No result found!</p></div>

 

      return (
        <div>
        <h3 className="product__catergory">{"គ្រឿងលេង"} <span>{window.location.pathname.substring(1).replace(/\//g, " ")}</span></h3>
        <Modal handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} setOpen={setOpen} title={title} image={image} />
        <div className="product__container">
        
            {filtered.map(({img,title,discount,price,delivery,slug,preorder},id)=>{
                return(
                   
                    <motion.artical variants={variant} initial="hidden" animate="visible" transition={{
                        delay: id * 0.25,
                        ease: "easeInOut",
                        duration: 0.5,
                      }} viewport={{ amount: 0 }} style={darkMode?{background:"#232323"}:null} key={id} className="product__card">
                        <div className="product__image" style={{flex:"1",width:"100%"}}>
                        {img ? (
                            // <img src={img} onClick={()=>{handleClickOpen(title,img)}} alt="image" style={{ width: "100%", height: "100%" }} />
                            // <ReactImageFallback
                            //   src={img}
                            //   style={{ width: "100%", height: "100%" }} 
                            //   onClick={()=>{handleClickOpen(title,img)}}
                            //   fallbackImage={IMG1}
                            //   initialImage={IMG1}
                            //   alt="cool image should be here"
                            //   className="my-image" />
                            <Swiper style={{"--swiper-navigation-size": "25px"}} slidesPerView={'auto'} freeMode={'false'} modules={[Autoplay, Pagination, Navigation]} pagination={{clickable:true}} navigation={true} className="product__image">
                              {Array.isArray(img) ? (
                                // If img is an array of image URLs
                                img.map((imageUrl, index) => (
                                  <SwiperSlide key={index} className='product__slider'>
                                    <ReactImageFallback
                                      src={imageUrl}
                                      style={{ width: "100%", height: "100%" }}
                                      onClick={() => handleClickOpen(title, imageUrl)}
                                      fallbackImage={IMG1}
                                      initialImage={IMG1}
                                      alt="cool image should be here"
                                      className="my-image"
                                    />
                                  </SwiperSlide>
                                ))
                              ) : (
                                // If img is a single image URL
                                <SwiperSlide className='product__slider'>
                                  <ReactImageFallback
                                    src={img}
                                    style={{ width: "100%", height: "100%" }}
                                    onClick={() => handleClickOpen(title, img)}
                                    fallbackImage={IMG1}
                                    initialImage={IMG1}
                                    alt="cool image should be here"
                                    className="my-image"
                                  />
                                </SwiperSlide>
                              )}
                              {/* <SwiperSlide className='product__slider' style={{display:!img?'none':null}}><img src={img} alt="" /></SwiperSlide>
                              <SwiperSlide className='product__slider' style={{display:!img?'none':null}}><img src={img} alt="" /></SwiperSlide> */}
                            </Swiper>
                        ) : (
                          <div className="products__image skeleton__container" style={{position:"relative",width:"90%",margin:"auto",aspectRatio:"1/1"}}>
                            <img src={IMG1} style={{flex: "1 1 0%",visibility:"hidden", width:'maxWidth',display:"block"}} alt="image" />  
                            <Skeleton containerClassName="flex-1" baseColor={darkMode?'var(--third-color)':null} style={{position:"absolute",width:'maxWidth',top:"0",bottom:"0"}}/>                 
                          </div>
                        )}
                        </div>
                        <div className="product__spec">
                            <div className="product__spec-wrapper">
                               <h4>{title}</h4>
                               <h5><span>តម្លៃ: </span><span style={discount?{textDecoration:"line-through"}:null}>{discount}</span> {price} + <span>សេវាផ្ញើរ</span> {delivery}</h5>
                               {/* <a className="buy__now" href="https://m.me/Lyath.Chhay">{preorder?"មានទទួលកម្ម៉ង់":"Buy Now"}</a> */}
                               <a className="buy__now" href="https://t.me/lyath11">{preorder?"មានទទួលកម្ម៉ង់":"Buy Now"}</a>
                               
                            </div>
                        </div>
                </motion.artical> 
           
                )
            })}
        </div>
    </div>
      );
}


export default Product;