import React from 'react'
import "./categories.css"
import {NavLink,Link,Routes,Route} from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/swiper-bundle.min.css";
import 'swiper/modules/autoplay/autoplay.min.css'
import CAT1 from "../assets/images/download-removebg-preview.png"
import CAT2 from "../assets/images/download__1_-removebg-preview.png"
import CAT3 from "../assets/images/download__2_-removebg-preview.png"
import CAT4 from "../assets/images/download__3_-removebg-preview.png"
import CAT5 from "../assets/images/download__4_-removebg-preview.png"
import CAT6 from "../assets/images/2022-Honda-Winner-X-Vietnam-11.png"
import CAT7 from "../assets/images/aerox_3c2538a3-15dd-45d3-bdb1-599dbcfa13fe_1200x.png"
import Skeleton from 'react-loading-skeleton'
import { useState, useEffect } from 'react'
//firebase
import { db, storage } from '../firebase';
import { uploadBytesResumable,ref, getDownloadURL, getStorage, uploadString } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, serverTimestamp, updateDoc, onSnapshot, orderBy, query, deleteDoc } from 'firebase/firestore';


const Categories = ({darkMode,item}) => {

    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    //get all categories
    useEffect(()=>{
      setLoading(true);
      const q = query(collection(db, 'categories'), orderBy('timestamp','desc'));
      const unSub = onSnapshot(q, (snapshot)=>{
        let list = [];
        snapshot.docs.forEach((doc)=>{
          list.push({id:doc.id, ...doc.data()})
        });
        setCategories(list);
        console.log(categories)
        setLoading(false);
      },(error)=>{console.log(error)})
      return () => {unSub()};
    },[])

  
  return (
    <>
    <h4 className="categories__title">Categories</h4>
    <Swiper spaceBetween={15} slidesPerView={"auto"} modules={[Navigation]} breakpoints={{
        }} className='categories__container'>
          {loading
          ?
           [...Array(6)].map((id)=>(
                <SwiperSlide className='category__slide'><Skeleton width={70} baseColor={darkMode?'var(--third-color)':null} height={60} /></SwiperSlide>
           ))
          :
          <span>
              <SwiperSlide className='category__slide'><NavLink to="/" end  className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT2} alt="" />All</NavLink></SwiperSlide>
                {categories.length > 0 && categories.map((category)=>(
              <SwiperSlide className='category__slide'><NavLink to={encodeURIComponent(category.category_title.replace(/\s/g, '').toLowerCase())} end  className="link__container"><img style={darkMode?{background:'#232323'}:null} src={category.category_image} alt="" />{category.category_title}</NavLink></SwiperSlide>
            ))}
          </span>
          }
          {/* <SwiperSlide className='category__slide'><NavLink to="/" end  className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT2} alt="" />Click</NavLink></SwiperSlide>
          <SwiperSlide className='category__slide'><NavLink to="/ADV" className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT1} alt="" />ADV</NavLink> </SwiperSlide>
          <SwiperSlide className='category__slide'><NavLink to="/click160i" className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT4} alt="" />Click 160i</NavLink></SwiperSlide>
          <SwiperSlide className='category__slide'><NavLink to="/scoopy" className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT5} alt="" />Scoopy</NavLink>    </SwiperSlide>
          <SwiperSlide className='category__slide'><NavLink to="/Winner X" className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT6} alt="" />Winner X</NavLink>  </SwiperSlide>
          <SwiperSlide className='category__slide'><NavLink to="/aerox" className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT7} alt="" />Aero X</NavLink>   </SwiperSlide>
          <SwiperSlide className='category__slide'><NavLink to="/pcx" className="link__container"><img style={darkMode?{background:'#232323'}:null} src={CAT3} alt="" />PCX</NavLink>        </SwiperSlide> */}
    </Swiper>
    </>
  )
}

export default Categories
